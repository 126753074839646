import * as tf from '@tensorflow/tfjs';
import * as tmImage from '@teachablemachine/image';
import './App.css';
import React from 'react';

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
        <p> This is a static app. Your data is only processed locally and not sent anywhere.
         Click on the button below and allow webcam permissions to get started. For best results, sit up straight and center your face in the frame. </p> 
        <button onClick={init}>Start</button>
        <br/>
        <div id="webcam-container"></div>
        <div id="label-container"></div>        
        </header>
      </div>
    );
  }

}

let model, webcam, labelContainer, maxPredictions;
const URL = "https://teachablemachine.withgoogle.com/models/FSphzomCH/";

    let isIos = false; 
    // fix when running demo in ios, video will be frozen;
    if (window.navigator.userAgent.indexOf('iPhone') > -1 || window.navigator.userAgent.indexOf('iPad') > -1) {
      isIos = true;
    }
    // Load the image model and setup the webcam
    async function init() {
        const modelURL = URL + 'model.json';
        const metadataURL = URL + 'metadata.json';

        // load the model and metadata
        // Refer to tmImage.loadFromFiles() in the API to support files from a file picker
        // or files from your local hard drive
        model = await tmImage.load(modelURL, metadataURL);
        maxPredictions = model.getTotalClasses();

        // Convenience function to setup a webcam
        const flip = true; // whether to flip the webcam
        const width = 300;
        const height = 200;
        webcam = new tmImage.Webcam(width, height, flip);
        webcam.setup({ facingMode: "user" }); // use "user" to use front-cam on mobile phones
        await webcam.setup(); // request access to the webcam

        if (isIos) {
            document.getElementById('webcam-container').appendChild(webcam.webcam); // webcam object needs to be added in any case to make this work on iOS
            // grab video-object in any way you want and set the attributes
            const webCamVideo = document.getElementsByTagName('video')[0];
            webCamVideo.setAttribute("playsinline", true); // written with "setAttribute" bc. iOS buggs otherwise
            webCamVideo.muted = "true";
            webCamVideo.style.width = width + 'px';
            webCamVideo.style.height = height + 'px';
        } else {
            document.getElementById("webcam-container").appendChild(webcam.canvas);
        }
        // append elements to the DOM
        labelContainer = document.getElementById('label-container');
        for (let i = 0; i < maxPredictions; i++) { // and class labels
            labelContainer.appendChild(document.createElement('div'));
        }
        webcam.play();
        window.requestAnimationFrame(loop);
    }

    async function loop() {
        webcam.update(); // update the webcam frame
        await predict();
        window.requestAnimationFrame(loop);
    }

    // run the webcam image through the image model
    async function predict() {
      // predict can take in an image, video or canvas html element
      for (let i = 0; i < maxPredictions; i++) {
        const prediction = await model.predict(webcam.canvas)
        var curr_max = 0
        var max_label = ""
        for (let i = 0; i < prediction.length; i++) {
          if (prediction[i].probability > curr_max) {
            max_label = prediction[i].className;
            curr_max = prediction[i].probability
          }
        }
          const classPrediction =
              "Mask Type detected: " + max_label
          labelContainer.childNodes[0].innerHTML = classPrediction;
      }
  }
 
export default App;
